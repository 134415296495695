<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <div class="custom-margin-x" v-if="!loading">
        <v-tabs hide-slider show-arrows v-model="firstTab" height="145px" centered
                background-color="transparent" color="#39bf88" class="pt-1 pb-6"
                active-class="page-tab-active">
          <v-tab class="page-tab-text elevation-10" :style="{ color: firstTab == 0 ? '#39bf88' : '#98a2ab' }"
                 style="height:75px;margin-top: 70px;width:250px!important"><span
              class="page-tab-span text-capitalize">First Time</span>
          </v-tab>
          <v-tab class="page-tab-text elevation-10" :style="{ color: firstTab == 1 ? '#39bf88' : '#98a2ab' }"
                 style="height:75px;margin-top: 70px;width:250px!important"><span
              class="page-tab-span text-capitalize">Renewal</span></v-tab>
          <v-tab class="page-tab-text elevation-10" :style="{ color: firstTab == 2 ? '#39bf88' : '#98a2ab' }"
                 style="height:75px;margin-top: 70px;width:250px!important">
            <span class="page-tab-span text-capitalize">Upgrade</span>
          </v-tab>
        </v-tabs>
        <v-tabs hide-slider show-arrows v-model="secondTab" height="145px" centered
                background-color="transparent" color="#39bf88" class="pt-1 pb-6"
                active-class="page-tab-active">
          <v-tab class="page-tab-text elevation-10" :style="{ color: secondTab == 0 ? '#39bf88' : '#98a2ab' }"
                 style="height:75px;margin-top: 70px;width:300px!important"><span
              class="page-tab-span text-capitalize">BAR</span>
          </v-tab>
          <v-tab class="page-tab-text elevation-10" :style="{ color: secondTab == 1 ? '#39bf88' : '#98a2ab' }"
                 style="height:75px;margin-top: 70px;width:300px!important"><span
              class="page-tab-span text-capitalize">BAR Bundle</span></v-tab>
          <v-tab class="page-tab-text elevation-10" :style="{ color: secondTab == 2 ? '#39bf88' : '#98a2ab' }"
                 style="height:75px;margin-top: 70px;width:300px!important"
          ><span
              class="page-tab-span text-capitalize">BAR Live online</span>
          </v-tab>
          <v-tab class="page-tab-text elevation-10" :style="{ color: secondTab == 2 ? '#39bf88' : '#98a2ab' }"
                 style="height:75px;margin-top: 70px;width:300px!important"
          ><span
              class="page-tab-span text-capitalize">BAR Live in Person</span>
          </v-tab>
        </v-tabs>
        <v-row v-if="plans.length" class="mt-5"
               style="margin-bottom: 300px!important">
          <v-col v-if="plans.length!==4"></v-col>
          <v-col
              lg="3"
              md="3"
              sm="12"
              style="margin-bottom:250px;"
              v-for="plan in plans" :key="plan.id">
            <plan-card :plan="plan"></plan-card>
          </v-col>
          <v-col v-if="plans.length!==4"></v-col>
        </v-row>
        <v-row v-else class="mt-16" style="margin-bottom: 300px!important">
          <v-col cols="12" class="text-center">
            <v-btn style="color: #ffbe66; font-size: 30px; font-family: Blinker-SemiBold,serif!important" text
                   to="/login-register">
              {{ planShowMessage }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" class="mt-1">
          <v-row style="background-color: #39bf88">
            <v-col style="font-family: Blinker-Bold!important"
                   :class="firstTab === 0 ? 'underlined-black-text' : 'white--text'" @click="firstTab = 0">
              First Time
            </v-col>
            <v-col style="font-family: Blinker-Bold!important"
                   :class="firstTab === 1 ? 'underlined-black-text' : 'white--text'" @click="firstTab = 1">
              Renewal
            </v-col>
            <v-col style="font-family: Blinker-Bold!important"
                   :class="firstTab === 2 ? 'underlined-black-text' : 'white--text'" @click="firstTab = 2">
              Upgrade
            </v-col>
          </v-row>
          <v-row class="mt-5" style="background-color: #39bf88">
            <v-col cols="2" @click="secondTab=0" :class="secondTab === 0 ? 'underlined-black-text' : 'white--text'"
                   style="font-family: Blinker-Bold; font-size: 13px">Bar
            </v-col>
            <v-col cols="2" @click="secondTab=1" :class="secondTab === 1 ? 'underlined-black-text' : 'white--text'"
                   style="font-family: Blinker-Bold; font-size: 13px">Bar Live
            </v-col>
            <v-col cols="4" @click="secondTab=2" :class="secondTab === 2 ? 'underlined-black-text' : 'white--text'"
                   style="font-family: Blinker-Bold; font-size: 13px">Bar Live Online
            </v-col>
            <v-col cols="4" @click="secondTab=3" :class="secondTab === 3 ? 'underlined-black-text' : 'white--text'"
                   style="font-family: Blinker-Bold; font-size: 13px">Bar Live In Person
            </v-col>
          </v-row>

          <v-row v-if="plans.length" class="mt-4"
                 style="margin-bottom: 100px!important">
            <v-col
                cols="6"
                class="pa-4"
                v-for="plan in plans" :key="plan.id">
              <div
                  style="border-radius: 12px;font-family: Blinker-Bold!important;
                  color: #3d3d3d!important;
                  border-style: solid;border-color: #39bf88!important;"
              >
                <div class="white--text" style="background-color: #39bf88!important;">
                  {{ plan.title }}
                </div>
                <div v-html="plan.description">

                </div>
                <div v-if="plan.expire">
                    <span style="font-family: Blinker-SemiBold,serif!important;color: #2d3136!important">
                      {{ plan.expire }} / days  &nbsp; {{ plan.price }} $
                    </span>
                </div>
                <div v-else>
                  <span style="font-family: Blinker-SemiBold,serif!important;color: #2d3136!important">∞</span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-else class="mt-4" style="margin-bottom: 30px!important">
            <v-col cols="12" class="text-center">
              <v-btn style="color: #ffbe66; font-size: 15px; font-family: Blinker-SemiBold,serif!important" text
                     to="/login-register">
                {{ planShowMessage }}
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </div>
  </div>

  <!--  <v-container style="margin-top: -37vh!important;margin-bottom: 35vh!important">-->
  <!--  <v-row>-->
  <!--      <v-col style="margin-top:35vh" v-for="plan in plans" :key="plan.id"  cols="4">-->
  <!--        <plan-card :plan="plan"></plan-card>-->
  <!--      </v-col>-->
  <!--    </v-row>-->
  <!--  </v-container>-->
</template>
<script>
import PlanCard from "@/components/PlanCard";

export default {
  name: "Plans",
  components: {PlanCard},
  data() {
    return {
      screenType: null,
      loading:true,
      firstTab: null,
      secondTab: 1,
      planShowMessage: null,
      plans: [],
      planDetails: {
        parent: 'first_time',
        child: 'bar_bundle'
      },
    }
  },
  watch: {
    secondTab: function (value) {
      this.plans = []
      switch (value) {
        case 0:
          this.planDetails.child = 'bar'
          break;
        case 1:
          this.planDetails.child = 'bar_bundle'
          break;
        case 2:
          this.planDetails.child = 'bar_live_simple'
          break;
        case 3:
          this.planDetails.child = 'bar_live_in_person'
          break;
      }
      this.getPlans()
    },
    firstTab: function (value) {
      this.plans = []
      switch (value) {
        case 0:
          this.planDetails.parent = 'first_time'
          break;
        case 1:
          this.planDetails.parent = 'renew'
          break;
        case 2:
          this.planDetails.parent = 'upgrade'
          break;
      }
      this.getPlans()
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    getPlans() {
      this.loading = true
      window.axios.post(`api/plans`, {type: this.planDetails.child, use_type: this.planDetails.parent})
          .then((response) => {
            this.plans = response.data.data
            this.loading = false
          }).catch((err) => {
        if (err.response.status === 400) {
          this.planShowMessage = err.response.data.message
          this.loading = false
        }
      })
    }
  },
  mounted() {
    this.getScreenType()
    this.getPlans()
    // window.axios.get(`api/plans/all`,)
    //     .then((data) => {
    //       this.plans = data.data.data
    //     })
  }
}
</script>

<style scoped>
.custom-margin-x {
  width: 1200px !important;
  display: table !important;
  margin: auto !important;
}
.page-tab-text {
  margin-top: 30px;
  width: 30%;
  height: 100%;
  font-size: 25px !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  background-color: #dce1e5 !important;
}

@media only screen and (max-width: 560px) {
  .page-tab-text {
    font-size: 17px !important;
  }
}

.page-tab-active {
  height: 160px !important;
  background-color: white !important;
  font-size: 32px !important;
  padding: 5% !important;
  z-index: 1000 !important;
  transition: transform 0.5s ease;
  transform: translateY(-40px);
}

.page-tab-span {
  font-family: Blinker-SemiBold, serif;
  top: 15px !important;
  letter-spacing: normal !important;
  position: absolute;
  font-size: 30px !important;
}

</style>